import React, { Component } from "react";
import Draggable from "react-draggable"; // The default
import { GrammarlyEditorPlugin } from "@grammarly/editor-sdk-react";
import Navigation from "../navcomponent/Navigation";
class MemeComponent extends Component {
  constructor(props) {
    super(props);
    this.settings = {
      meme: window.history.state.usr.meme,
      inputTextOne: `Text 1`,
      inputTextTwo: `Text 2`,
      memeCreationApi: `https://imgflip.artefaktas.workers.dev/caption`,
    };

    this.typeArray = [
      {
        type: "Text fields",
        value: 0,
      },
      { type: "Boxes", value: 1 },
    ];

    this.state = {
      inputOne: null,
      inputTwo: null,
      generatedImage: null,
      memeId: this.settings.meme.id,
      selectedType: this.typeArray[0].value,
      fontHeight: 40,
      boxes: [
        { value: null, x: null, y: null },
        { value: null, x: null, y: null },
        { value: null, x: null, y: null },
        { value: null, x: null, y: null },
        { value: null, x: null, y: null },
      ],
      boxData: false,
      mouseCursor: {
        x: null,
        y: null,
      },
    };
    this.boxesStyle = {
      fontSize: this.state.fontHeight,
      color: "white",
      background: "black",
      opacity: `0.4`,
    };
    this.changeEvent = this.changeEvent.bind(this);
    this.boxChange = this.boxChange.bind(this);
    this.fontChange = this.fontChange.bind(this);
    this.objGeneration = this.objGeneration.bind(this);
  }

  changeEvent(val) {
    let box = this.state.boxes;
    box.forEach((element) => {
      element.value = null;
    });
    this.setState({ boxes: box });
    this.setState({
      selectedType: parseInt(val),
      inputOne: null,
      inputTwo: null,
      boxData: false,
    });
    this.setState({ generatedImage: null });
  }

  boxChange(val, index) {
    let ar = this.state.boxes;
    let currentItem = ar[index];
    if (val === "") {
      val = null;
    }
    currentItem.value = val;
    this.setState({ boxes: ar, boxData: true });
  }

  coordChange(data, index, draggableEl) {
    let memeContainer = document.querySelector("#mainImage");
    let memeBoundaries = memeContainer.getBoundingClientRect();
    let memeX = memeBoundaries.left;
    let memeY = memeBoundaries.bottom;
    let draggableX = draggableEl.left;
    let draggableY = draggableEl.bottom;
    let memeCord = {
      x: memeX,
      y: memeY,
    };
    let draggableCord = {
      x: draggableX,
      y: draggableY,
    };
    const difference = {
      x: draggableCord.x - memeCord.x,
      y: memeCord.y - draggableCord.y,
    };
    const point = {
      x: difference.x + 40,
      y: memeBoundaries.height - difference.y + 40,
    };
    let ar = this.state.boxes;
    let currentItem = ar[index];
    currentItem.y = point.y;
    currentItem.x = point.x;
    this.setState({ boxes: ar });
  }

  idChange(val) {
    if (val === "") {
      this.setState({ memeId: this.settings.meme.id });
    }
    this.setState({ memeId: val });
  }

  fontChange(val) {
    this.setState({ fontHeight: val }, () => {
      this.boxesStyle.fontSize = this.state.fontHeight;
    });
  }

  objGeneration() {
    let memeId = this.state.memeId;
    let obj = {};
    obj.template_id = memeId;
    if (this.state.boxData === true) {
      let newData = [];
      let ob;
      let activeCount = 0;
      this.state.boxes.forEach((element, index) => {
        if (element.value !== null) {
          activeCount = activeCount + 1;
        }
      });
      let fontHeight = this.state.fontHeight;
      this.state.boxes.forEach((element, index) => {
        if (element.value !== null) {
          ob = {
            text: this.state.boxes[index].value,
            y: this.state.boxes[index].y,
            x: this.state.boxes[index].x,
            width: 548,
            height: fontHeight,
            color: "#ffffff",
            outline_color: "#000000",
          };

          newData.push(ob);
        }
      });
      obj.boxes = newData;
    } else {
      obj.text0 = this.state.inputOne;
      obj.text1 = this.state.inputTwo;
    }

    async function getData(passedUrl, ob) {
      const req = await fetch(passedUrl, {
        method: "POST",
        body: JSON.stringify([ob]),
        headers: {
          "Content-Type": "application/json",
          "User-Agent": "artefaktasbot",
        },
      });
      const jsonData = req.json();
      return jsonData;
    }
    getData(this.settings.memeCreationApi, obj).then((res) => {
      this.setState({ generatedImage: res.data.url });
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <Navigation />
        </div>
        <div className="container">
          <div className="row align-items-center vh-100">
            <div className="col-md-4 col-sm-12 col-xs-12 my-2">
              <div className="card">
                <img
                  id="mainImage"
                  className="card-img-top"
                  src={this.settings.meme.url}
                  alt="Card cap"
                />
                <div className="card-body">
                  <h5 className="card-title">{this.settings.meme.name}</h5>
                  <div className="card-text">
                    {this.state.inputOne !== null &&
                    this.state.selectedType === 0 ? (
                      <p>{this.state.inputOne}</p>
                    ) : (
                      ""
                    )}
                    {this.state.inputTwo !== null &&
                    this.state.selectedType === 0 ? (
                      <p>{this.state.inputTwo}</p>
                    ) : (
                      ""
                    )}
                    {this.state.selectedType !== 0
                      ? this.state.boxes.map((box, index) => {
                          return (
                            <Draggable
                              key={index}
                              offsetParent={document.querySelector(
                                "#mainImage"
                              )}
                              onStop={(e, data) => {
                                const cnt = index;
                                this.coordChange(
                                  data,
                                  index,
                                  document
                                    .querySelectorAll(".react-draggable")
                                    [cnt].getBoundingClientRect()
                                );
                              }}
                            >
                              <p
                                draggable="true"
                                style={this.boxesStyle}
                                key={index}
                              >
                                {box.value}
                              </p>
                            </Draggable>
                          );
                        })
                      : ""}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12 my-2">
              <div className="card">
                <div className="card-body">
                  <select
                    className="form-control form-control-lg my-4"
                    defaultValue={this.state.selectedType}
                    onChange={(ev) => {
                      this.changeEvent(
                        ev.currentTarget.selectedOptions[0].value
                      );
                    }}
                  >
                    {this.typeArray.map((element, index) => {
                      return (
                        <option key={index} value={element.value}>
                          {element.type}
                        </option>
                      );
                    })}
                  </select>
                  {this.state.selectedType === 0 ? (
                    <div>
                      <p className="card-text">
                        <GrammarlyEditorPlugin clientId="client_FtUxSiBYdZjKVZajkqi9dh">
                          <input
                            onChange={(e) => {
                              this.setState({
                                inputOne: e.currentTarget.value,
                              });
                            }}
                            className="form-control form-control-lg"
                            placeholder={this.settings.inputTextOne}
                            type="text"
                          ></input>
                        </GrammarlyEditorPlugin>
                      </p>
                      <p className="card-text">
                        <GrammarlyEditorPlugin clientId="client_FtUxSiBYdZjKVZajkqi9dh">
                          <input
                            onChange={(e) => {
                              this.setState({
                                inputTwo: e.currentTarget.value,
                              });
                            }}
                            className="form-control form-control-lg"
                            placeholder={this.settings.inputTextTwo}
                            type="text"
                          ></input>
                        </GrammarlyEditorPlugin>
                      </p>
                    </div>
                  ) : (
                    <div>
                      {this.state.boxes.map((box, index) => {
                        return (
                          <p className="card-text" key={index}>
                            <GrammarlyEditorPlugin clientId="client_FtUxSiBYdZjKVZajkqi9dh">
                              <input
                                onChange={(e) => {
                                  const inDex = index;
                                  this.boxChange(e.currentTarget.value, inDex);
                                }}
                                className="form-control form-control-lg"
                                placeholder={`Text ${index + 1}`}
                                type="text"
                              ></input>
                            </GrammarlyEditorPlugin>
                          </p>
                        );
                      })}
                    </div>
                  )}
                  <p className="card-text">
                    <input
                      onChange={(e) => {
                        this.idChange(e.currentTarget.value);
                      }}
                      className="form-control form-control-lg my-4"
                      placeholder={`Or enter a meme ID`}
                      type="text"
                    ></input>
                  </p>
                  <p className="card-text">
                    <input
                      onChange={(e) => {
                        this.fontChange(e.currentTarget.value);
                      }}
                      className="form-control form-control-lg my-4"
                      type="number"
                      defaultValue={this.state.fontHeight}
                    ></input>
                  </p>
                  <p className="card-text">
                    <button
                      className="btn btn-lg btn-dark my-4"
                      onClick={this.objGeneration}
                    >
                      Generate
                    </button>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-12 col-xs-12 my-2">
              <div className="card">
                {this.state.generatedImage !== null ? (
                  <img
                    className="card-img-top"
                    src={this.state.generatedImage}
                    alt="Card cap"
                  />
                ) : (
                  ""
                )}

                <div className="card-body">
                  <p className="card-text">
                    {this.state.generatedImage === null ? (
                      "No images were generated"
                    ) : (
                      <a
                        href={this.state.generatedImage}
                        className="btn btn-lg btn-dark my-4"
                        download={`${this.state.generatedImage}.png`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        Download
                      </a>
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MemeComponent;
