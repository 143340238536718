import React, { Component } from "react";
import { Link } from "react-router-dom";
import Navigation from "../navcomponent/Navigation";
class MainComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
    };
    this.settings = {
      apiUrl: "https://imgflip.artefaktas.workers.dev/api",
    };
  }

  componentDidMount() {
    async function getData(url) {
      const res = await fetch(url);
      const json = await res.json();
      return json;
    }
    getData(this.settings.apiUrl).then((res) => {
      if (res?.success === true) {
        this.setState({ data: res.data.memes });
      }
    });
  }

  render() {
    return (
      <div>
        <div className="container-fluid">
          <Navigation />
        </div>
        <div className="container">
          <div className="row">
            {this.state.data !== null ? (
              this.state.data.map((meme, index) => {
                return (
                  <div className="col-4 my-2" key={index}>
                    <div className="card h-100" style={this.cardStyle}>
                      <img
                        className="card-img-top"
                        src={meme.url}
                        alt="Card cap"
                      />
                      <div className="card-body">
                        <h3 className="card-title">{meme.name}</h3>
                        <p className="card-text"></p>
                        <Link
                          to={`/create/${meme.id}`}
                          state={{ meme: meme }}
                          className="btn btn-lg btn-dark"
                        >
                          Create your own
                        </Link>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <p>No results</p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default MainComponent;
